.instagram-share-image {
    width: 1080px;
    height: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: relative;
}

.instagram-share-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Darker background for better text visibility */
    z-index: 1;
}

.share-content {
    text-align: center;
    position: relative;
    z-index: 2;
}

/* Updated share image styles for better visibility */
.share-image {
    width: 50%;
    height: auto;
    border-radius: 5%;
    /* Circular format for logo */
    border: 4px solid #1bb6ca;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    /* Add depth to the image */
}

/* Updated Title for better hierarchy */
.share-title {
    font-size: 2.5rem;
    /* Increase size for the main title */
    font-weight: bold;
    margin: 1.5rem 0;
    color: white !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Poppins', sans-serif;
    /* Use modern sans-serif font */
}

/* Updated Title for better hierarchy */
.share-desc {
    font-size: 1.5rem;
    /* Increase size for the main title */
    font-weight: bold;
    margin: 1.5rem 0;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Poppins', sans-serif;
    /* Use modern sans-serif font */
}

/* Additional font sizes for other sections */
.share-location,
.share-departure,
.share-date,
.share-call-to-action {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Countdown box for clearer separation */
.countdown-box {
    /* Transparent background for the countdown */
    padding: 1rem;
    border-radius: 10px;
    font-size: 2.5rem;
    /* Larger font for days */
    color: #fff;
    /* Match your brand color */
    display: inline-block;
    text-align: center;
    margin: 1rem 0;
}

/* Call to Action */
.share-call-to-action {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1bb6ca;
    /* Make Kaleido color stand out */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 2rem;
}

.countdown-box h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff !important;
}
  