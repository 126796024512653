.cruiseQuote {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #415063;
  margin: 20px;
}

.timeline-header {
  font-size: 1.5rem;
  margin: 15px;
}

.cruiseQuoteContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}