@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
}



@media only screen and (max-width: 767px) {


}