
.withTravellers {
    font-size: 1.2rem;
    color: #415063;
}

.aboutJourney {
    font-size: 1rem;
    color: #415063;
    margin: 15px;
}

.swal-z-index {
    z-index: 2000 !important;
}

.swal2-popup {
    z-index: 2000 !important;
}