@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --white: #ffffff;
  --tiffany-blue: #84dcc6;
  --aquamarine: #a5ffd6;
  --melon: #ffa69e;
  --light-red: #ff686b;
  --highlight: #44CF6C;

  --classicBG: #bbcfdd;
  --classicText: #415063;
  --classicHighlight: #b6d99c;

  --gradient-top: linear-gradient(0deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
  --gradient-right: linear-gradient(90deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
  --gradient-bottom: linear-gradient(180deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
  --gradient-left: linear-gradient(270deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
  --gradient-radial: radial-gradient(#ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
}

.fullWidthHome {
  display: flex;
  width: 100% !important;
  margin: 0 auto;
  border-top: #1bb6ca 5px solid;
  justify-content: center;
}

.logo {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 506px;
}

.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: #415063;
  width: 20% !important;
  min-width: 325px;
}

.sideBarRight {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: #415063;
  width: 20% !important;
  min-width: 325px;
}

.sideBarItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.sideBarAccordionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1bb6ca !important;
  color: white !important;
  margin: 5px 30px 5px 30px;
  width: 300px;
}

.listItem {
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
}

.sideBarAccordion {
  background-color: #1bb6ca;
  color: white;
  margin: 5px 30px 5px 30px;
  width: 300px;
  transition: width 0.3s ease;
}

.sideBarAccordion.Mui-expanded {
  background-color: #bbcfdd;
  color: white;
  margin: 5px 30px 5px 30px;
  width: 300px;
}

.sideBarButton {
  margin: 5px 30px 5px 30px;
  width: 300px;
  background-color: #1bb6ca !important;
}

.homePageContainer {
  flex: 1;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100vw;
  overflow: hidden;
}

.App-header {
  background-color: var(--classicBG);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  font-family: 'Montserrat';
  color: var(--white);
  margin-bottom: 50px;
}

.App-link {
  color: var(--light-red);
}

.homeText {
  font-size: 3rem;
  font-family: 'Montserrat';
  font-weight: bold;
  color: var(--classicText);
  margin: 30px 5px 0 30px;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.highlight {
  color: #1bb6ca;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-size: 3rem;
  margin: 30px 30px 0 0;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.tagLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.taglinetext {
  font-size: 1.2rem;
  font-family: 'Montserrat';
  font-weight: 500;
  color: var(--classicText);
  margin: 0 0 0 10px;
}

.heroP {
  font-size: 1.2rem;
  color: var(--classicText);
  margin: 0 0 20px 0;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
}

.homeChips {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #1bb6ca !important;
  color: white !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: 'Montserrat';
}



@media (max-width: 600px) {


  .tagLine {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-right: 30px;
  }

  .homeText {
    font-size: 2.5rem;
  }

  .highlight {
    font-size: 2.5rem;
    margin: 30px 0 0 0;
  }

  .heroP {
    font-size: 1.2rem;
    color: var(--classicText);
    margin: 20px 0 20px 0;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }

  .homePageButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 20px 10px 0 10px;
  }

  .sideBar {
    display: none;
  }

  .sideBarRight {
    display: none;
  }

  .logo {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 310px;
  }

}