@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.food-share-image {
    width: 1080px;
    height: 1350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.instagram-share-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.share-content {
    text-align: center;
    position: relative;
    z-index: 2;
    width: 100%;
}

.item-share-image {
    position: absolute;
    bottom: 36px;
    width: 695px;
    height: 480px;
    right: -63px;
    overflow: hidden;
    object-fit: cover !important;
    background-color: black;
}

.item-share-title {
    font-family: 'Indie Flower', cursive;
    font-size: 2.5rem;
    font-weight: bold;
    color: #415063 !important;
    @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
}

.item-share-date {
    font-family: 'Indie Flower', cursive;
    font-size: 1.8rem;
    font-weight: bold;
    color: #415063 !important;
    /*font-family: 'Poppins', sans-serif;*/
}

.item-share-user {
    font-family: 'Indie Flower', cursive;
    font-size: 1.5rem;
    font-weight: bold;
    color: #415063 !important;
}

.image-container {
    position: absolute;
    bottom: 160px;
    right: 230px;
    width: 600px;
    height: 384px;
    overflow: hidden;
}

.item-share-desc {
    font-family: 'Indie Flower', cursive;
    font-size: 1.2rem;
    font-weight: bold;
    color: #415063 !important;
}

.item-polaroid-content {
    position: absolute;
    left: 215px;
    width: 650px;
    height: 193px;
    overflow: hidden;
    top: -157px;
}


.share-location,
.share-departure,
.share-date,
.share-call-to-action {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.countdown-box {
    padding: 1rem;
    border-radius: 10px;
    font-size: 2.5rem;
    color: #fff;
    display: inline-block;
    text-align: center;
    margin: 1rem 0;
}

/* Call to Action */
.share-call-to-action {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1bb6ca;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 2rem;
}

.countdown-box h2 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff !important;
}


.qr-code-container {
    position: absolute;
    bottom: 325px;
    width: auto;
    height: auto;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.qr-code-text {
    font-size: 2rem;
    font-weight: bold;
    color: #1bb6ca;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    left: -425px;
    bottom: -270px;
}

.qr-code {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    position: absolute;
    bottom: 102px;
    left: -86px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}