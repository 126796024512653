.sideBarRight p {
    font-size: 1rem;
    color: #fff;
}

.rightSideBarHeading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.rightSideBarSubHeading {
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    margin: 16px;
    padding: 2px;
}

.rightSideBarSection {
    margin-bottom: 20px;
}

.SideBarCategoryHeading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightSideBarStatus {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.blinking-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: lightgreen;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    animation: blink 3s ease-in-out infinite;
}

.blinking-dot-stats {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    animation: blink 3s ease-in-out infinite;
}