/* Custom styles for react-comments-section */
.react-comments-section .rcs-input-box .form {
    flex-direction: row !important;
}

.react-comments-section .rcs-input-box .rcs-input {
    width: auto !important;
    flex: 1;
}

.react-comments-section .rcs-input-box .rcs-submit-button {
    align-self: flex-end;
    margin-top: 0 !important;
    margin-left: 10px;
}

.react-comments-section .rcs-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatIntro {
    display: block;
    font-size: 1rem;
    font-style: italic;
}

.cruisItemInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    /* Arrange children in a column */
}

.chatIntroContainer {
    text-align: center;
    /* Center the text */
    margin-top: 10px;
    /* Add some space between the header and the intro text */
}

.rcs-submit-button,
.rcs-cancel-button {
    display: block;
    /* Make the buttons block elements */
    width: 100%;
    /* Make the buttons take up the full width */
    margin-top: 10px;
    /* Add some space between the input and buttons */
}

.rcs-input-box {
    display: flex;
    flex-direction: column;
    /* Ensure the input and buttons are in a column */
}