@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
    --white: #ffffff;
    --tiffany-blue: #84dcc6;
    --aquamarine: #a5ffd6;
    --melon: #ffa69e;
    --light-red: #ff686b;

    --classicBG: #bbcfdd;
    --classicText: #415063;
    --classicHighlight: #b6d99c;

    --gradient-top: linear-gradient(0deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-right: linear-gradient(90deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-bottom: linear-gradient(180deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-left: linear-gradient(270deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-radial: radial-gradient(#ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
}

.filterText {
    font-size: 3rem;
    font-family: 'Montserrat';
    font-weight: bold;
    color: var(--classicText);
    margin: 30px 0 0 0;
}

.filterHighlight {
    color: #1bb6ca;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 2rem;
    margin: 30px 10px 0 10px;
}

.filterTagLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.destinationInput {
    height: 40px;
    width: 200px;
    margin: 20px;
}

.filterText {
    font-size: 1.5rem;
    font-family: 'Montserrat';
    font-weight: bold;
    color: var(--classicText);
    margin: 30px 0 0 0;
}

.filterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--white);
    border: 5px solid var(--freshHighlight);
    color: var(--white);
    border-radius: 30px;
    margin: 20px;
    width: 500px;
}

.filterLabel {
    font-size: 1.2rem;
    font-family: 'Montserrat';
    color: var(--classicText);
    margin: 20px 10px 0 0;
    height: 27px;
}

.filterItem {
    padding: 10px;
    margin-bottom: 20px;
}

.location-search-input {
    width: auto;
}

.common-input {
    font-size: 1rem;
    font-family: 'Montserrat';
    color: var(--classicText);
    padding: 10px;
    height: 3.5rem;
    width: 250px;
}

.journeyName {
    font-size: 2rem;
    font-family: 'Montserrat';
    font-weight: bold;
    color: var(--classicText);
    margin: 30px 0 0 0;
}

.journeyLocation {
    color: #1bb6ca;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 1.8rem;
    margin: 15px 10px 10px 10px;
}

.journeyFilterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 100%;
    background: var(--white);
    border: 2px solid var(--freshHighlight);
    color: var(--white);
    border-radius: 30px;
    margin: 20px 0 20px 0;
}

.journeyfilterTagLine {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-timeline {
    max-width: none !important;
    margin-left: auto;
    margin-right: auto;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    width: 150px !important;
    font-weight: bold;
    font-size: 1.5rem !important;
}

.first-element::before {
    background: none !important;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 80px !important;
    left: 18px;
    height: 95% !important;
    width: 4px;
    background: var(--line-color);
}

@media (max-width: 770px) {

    .itemType {
        font-size: 1.2em;
        font-family: 'Montserrat';
    }

    .hotelIcon {
        font-size: 2.5rem;
    }

    .filterBox {
        width: 85vw;
    }

    .vertical-timeline--layout-1-column .vertical-timeline-element-content {
        margin-left: 0 !important;
    }

    .filterTagLine {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    .filterHighlight {
        color: #1bb6ca;
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 800;
        font-size: 2rem;
        margin: 0px 10px 0 10px;
    }

    .common-input {
        font-size: 1rem;
        font-family: 'Montserrat';
        color: var(--classicText);
        height: 3.5rem;
    }
    

}