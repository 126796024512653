@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
    --white: #ffffff;
    --tiffany-blue: #84dcc6;
    --aquamarine: #a5ffd6;
    --melon: #ffa69e;
    --light-red: #ff686b;

    --classicBG: #bbcfdd;
    --classicText: #415063;
    --classicHighlight: #b6d99c;

    --freshHighlight: #1bb6ca;

    --gradient-top: linear-gradient(0deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-right: linear-gradient(90deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-bottom: linear-gradient(180deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-left: linear-gradient(270deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-radial: radial-gradient(#ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);


}

* {
    white-space: normal !important;
}

body {
    font-family: 'Montserrat', sans-serif;
}

hr {
    border: none;
    border-top: 1px solid var(--freshHighlight);
    color: var(--freshHighlight);
    overflow: visible;
    text-align: center;
    height: 1px;
    margin: 10px 0;
    width: 100%;
}

.previewModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 840px;
    overflow-y: hidden;
    background-color: "white";
    box-shadow: 24;
    padding: 20px;
    outline: none;
    background-color: var(--white);
}

.previewImage {
    width: 100%;
    height: auto;
    max-width: 1080px;
    max-height: 1350px;
    transform: scale(0.5);
    transform-origin: top left;
}

.swal2-popup {
    z-index: 1000000 !important;
}

.userChipNoPadding {
    padding: 0 !important;
}

.hand-drawn-rectangle {
    display: flex;
    justify-content: start;
    flex-direction: column;
    height: auto;
    background-color: var(--white);
    border: 5px solid var(--freshHighlight);
    border-radius: 30px !important;
    width: 700px;
}

.ExperiencesRectangle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    background-color: var(--white);
    border: 5px solid var(--freshHighlight);
    border-radius: 30px !important;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.itemChips {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.checkInChips {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 27%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.itemImage {
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 5%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.itemType {
    color: var(--classicText);
    font-size: 1.8rem;
    text-align: start;
    font-weight: 400;
}

.itemTitle {
    color: var(--classicText);
    font-size: 1.2rem;
    font-family: 500;
    text-align: start;
    margin: 0;
}

.itemBody {
    display: flex;
    flex-direction: column;
    color: var(--classicText);
    font-size: 1rem;
    align-items: start;
    justify-content: center;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.statsBody {
    display: flex;
    flex-direction: column;
    color: var(--classicText);
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.itemItinerary {
    display: flex;
    flex-direction: column;
    color: var(--classicText);
    font-size: 1rem;
    align-items: start;
    justify-content: center;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.itemUsername {
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: #415063;
    border-radius: 10%;
    overflow: hidden;
}

.itemUsername>* {
    border-radius: inherit;
}

.itemIcon {
    font-size: 2rem !important;
    color: var(--classicText);
    margin: .5em;
    border: 2px solid var(--freshHighlight);
    border-radius: 50%;
    padding: 2px;
}

.itemAddress {
    font-size: 1.3rem;
    text-align: start;
    margin-bottom: 5px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.itemCheckIn {
    font-size: 1rem;
    text-align: center;
}

.itemCheckIn span {
    display: inline;
}

.itemActions {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 10px 0;
    width: 100vw;
    font-weight: 800;
    width: 40vw;
    margin-left: 15px;
}

.itemActions svg {
    fill: var(--classicText);
}

.itemAvatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}

.itemTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.itemItinerary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.itemDate {
    font-size: 1rem;
    text-align: center;
    margin: 5px 0 5px 0;
}

.itemAbout {
    font-size: 1rem;
    text-align: center;
    margin: 0 10px 10px;
}

.itemTravellers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px;
}

.statItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.itemSocials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.journeyItemLocaion {
    color: #1bb6ca;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 1.5rem;
}

.itinerary {
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1rem;
    text-align: start;
}

.tick-credits {
    display: none !important;
}

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1em;
}

.countdown-item {
    font-size: 1.2rem;
    /* Adjust the font size as needed */
    font-weight: bold;
    width: 10vw;
}

.itemCountriesVisited {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.iconImage {
    width: 24px;
    height: 24px;
}

.itemName {
    font-size: 2rem;
    font-weight: 800;
    color: var(--classicText);
    margin: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.itemButtons {
    display: flex;
    align-items: center;
    justify-content: start;
}

.itemInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px 0 8px;
}

.itemInfoSingle {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0 20px;
}

.time {
    text-align: center;
    margin: 2px;
}

.heading {
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    font-size: 1rem;
    color: var(--classicText);
}

.commentSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 30vw;
}

.topHalf {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ratings {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.feedItem {
    position: relative;
    width: 700px;
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.userNameText {
    color: var(--classicBG);
    font-size: 1rem;
    border-radius: inherit;
    width: 0 35px;
}

.userChip {
    z-index: 1;
    color: white;
    background-color: #415063;
}

.chipContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px 10px;
}

.experienceHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0px 0px 20px 10px !important;
}

.itemHeader {
    display: flex;
    justify-content: start;
    color: var(--classicText);
    font-size: 1.5rem;
}

.itemExpand {
    display: flex;
    justify-content: start;
    color: var(--classicText);
    font-size: 1.2rem;
}

.statRank {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.itemJourney {
    display: block;
}

.reviewSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 90%;
}

.statSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 90%;
}

.inputLabelOverride {
    white-space: normal !important;
}

.loadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

@media (max-width: 600px) {

    .hand-drawn-rectangle {
        width: 85vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .feedItem {
        width: 97vw;
    }

    .itemName {
        font-size: 1.7rem;
    }

    .itemAddress {
        font-size: .8rem;
        text-align: center;
    }

    .userChip {
        font-size: .9rem !important;
    }



    .itemImage {
        width: 290px;
        height: 290px;
        object-fit: cover;
    }

    .previewImage {
        width: 100%;
        height: auto;
        max-width: 1080px;
        max-height: 1350px;
        transform: scale(0.29);
        transform-origin: top left;
    }

    .previewModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 99vw;
        height: 560px;
        overflow-y: hidden;
        background-color: "white";
        box-shadow: 24;
        padding: 20px;
        outline: none;
        background-color: var(--white);
    }

    .itemChips {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 77%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
}