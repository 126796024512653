@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
    --white: #ffffff;
    --tiffany-blue: #84dcc6;
    --aquamarine: #a5ffd6;
    --melon: #ffa69e;
    --light-red: #ff686b;

    --classicBG: #bbcfdd;
    --classicText: #415063;
    --classicHighlight: #b6d99c;

    --freshHighlight: #1bb6ca;

    --gradient-top: linear-gradient(0deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-right: linear-gradient(90deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-bottom: linear-gradient(180deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-left: linear-gradient(270deg, #ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
    --gradient-radial: radial-gradient(#ffffff, #84dcc6, #a5ffd6, #ffa69e, #ff686b);
}

body {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    z-index: 9999;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--classicText);
}

.swal {
    z-index: 10000 !important;
}

.inputLabel {
    color: var(--classicText) !important;
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
    margin-Bottom: 10px;
}

.select {
    min-width: 200px;
}

.form-container {
    padding: 20px 0;
    margin-top: 100px;
}

.formHeading {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 10px 0;
}

.headingdark {
    font-family: 'Montserrat', 'sans-serif';
    color: var(--classicText);
    font-size: 2rem;
    text-align: center;
    margin: 10px 0;
}

.headingLight {
    font-family: 'Montserrat', 'sans-serif';
    color: var(--freshHighlight);
    font-size: 2rem;
    text-align: center;
    margin: 10px 8px;
    font-style: italic;
}



.Itemform {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    background-color: var(--white);
    border: 2px solid var(--freshHighlight);
    border-radius: 30px !important;
    padding: 20px;
}

.formControl {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}

.hotel-input {
    height: 20px;
}

.submitButton {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70px;
    height: auto;
    margin: 10px 0;
    padding: 10px;
    background-color: var(--freshHighlight);
    color: var(--white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: var(--classicHighlight);
}

.cancelButton {
    background-color: #f44336;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70px;
    height: auto;
    margin: 10px 10px;
    padding: 10px;
    color: var(--white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.formButtons {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {

    .Itemform {
        width: 90vw;
        margin-top: 0;
        margin-bottom: 20px;
        height: auto;
    }

    .form-container {
        height: auto;
        margin-top: 100px;
    }

    .headingdark {
        font-size: 1.4rem;
    }

    .headingLight {
        font-size: 1.5rem;
    }


}